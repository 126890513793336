<template>
  <div>
       <!-- ?true:?true:?true:  -->
      <!-- NAVIGATION  -->
    <div v-if="modalstatus" @click="modalstatus=false" style="position:fixed;width:100vw;height:100vh;left:0;top:0;z-index:100;background:rgba(7, 6, 6, 0.671);"></div>
    <div v-if="modalstatus" style="position: fixed;top: 50%;left: 50%;transform: translateX(-50%) translateY(-50%);z-index:105;min-width:50vw;">
      <div class="rounded-lg shadow p-3 bg-white animate__animated animate__bounceIn">
          <p class="font-semibold text-sm capitalize pb-3 pt-3">
             {{$store.state.users.username}}
             <button type="button" class="btn btn-sm btn-success ml-2" v-if="statuspilih=='1'"></button>
            <button type="button" class="btn btn-sm btn-danger  ml-2"  v-if="statuspilih=='2'"></button>
            <button type="button" class="btn btn-sm btn-warning ml-2 " v-if="statuspilih=='3'"></button>
          </p>
           <p class="font-semibold">Ubah status : </p>
          <button type="button" @click="ubahstatus('1')" class="btn btn-sm btn-success ml-2  ">Ready</button>
          <button type="button" @click="ubahstatus('2')" class="btn btn-sm btn-danger  ml-2 ">Sibuk</button>
          <button type="button" @click="ubahstatus('3')" class="btn btn-sm btn-warning ml-2">Istirahat/Tidak Dikantor</button>
      </div>
    </div>
    <div v-if="modalItem" @click="modalItem=false" style="position:fixed;width:100vw;height:100vh;left:0;top:0;z-index:100;background:rgba(7, 6, 6, 0.671);"></div>
    <div v-if="modalItem" style="position: fixed;top: 50%;left: 50%;transform: translateX(-50%) translateY(-50%);z-index:105;min-width:50vw;">
      <div class="rounded-lg shadow p-3 bg-white animate__animated animate__fadeIn">
         <p class="font-semibold">List item</p>
         <div class="table-responsive">
           <table class="table table-sm table-striped table-bordered">
             <tr>
               <td>id Produk</td>
               <td>Nama Produk</td>
               <td>Harga</td>
               <td>Jumlah</td>
               <td>Diskon</td>
               <td>Total</td>
             </tr>
             <tr v-for="(item, index) in listItem" :key="index">
                <td>{{item.id_produk}}</td>
                <td>{{item.nama_produk}}</td>
                <td>Rp. {{item.harga}}</td>
                <td>{{item.jumlah}}</td>
                <td>{{item.diskon}}</td>
                <td>Rp. {{(parseInt(item.jumlah)*parseInt(item.harga))-((parseInt(item.jumlah)*parseInt(item.harga))*(parseInt(item.diskon)/100))}}</td>
             </tr>
           </table>
         </div>
      </div>
    </div>
      <!-- <img src="@/static/hospital.png" class="img-fluid" style="height:40px;width:40px;position:absolute;right:10px;top:10px;" alt=""> -->
      <p class="text-center font-semibold">LIST TICKET FLOW</p>
      <button type="button" @click="$router.go(-1)" class="btn btn-sm btn-dark mb-2 "><span class="typcn typcn-arrow-left"></span> Kembali</button>
      <div class="ml-3 sm:ml-0">
          <div class="row bg-white shadow rounded-b-lg p-4 scrollbarnya " >
            <div class="col-12">
                <button type="button"  class="btn btn-sm btn-dark  float-right" @click="getUser"><span class="typcn typcn-arrow-loop"></span></button>
                <p class="font-semibold">Status User: </p> 
                <p><button type="button" class="btn btn-sm btn-success  "></button> = Ready, <button type="button" class="btn btn-sm btn-danger  "></button> = Ada Kerjaan, <button type="button" class="btn btn-sm btn-warning  "></button> = Istirahat/tidak dikantor</p>
            </div>
             <div class="col-12">
                <hr class="mt-5 mb-5">
            </div>
            <div class="col-12 mt-3" >
              <p class="text-sm p-3 font-semibold">List User : </p>
                <span class="ml-2" v-for="(item, index) in listuser" :key="index+'listuser'">
                    <button type="button" class="btn btn-sm btn-dark uppercase text-xs" @click="modalstatusshow(item)">
                    {{item.username}}
                    </button>
                    <button type="button" class="btn btn-sm btn-success ml-2 " v-if="item.status=='1' || item.status==null"></button>
                    <button type="button" class="btn btn-sm btn-danger  ml-2"  v-if="item.status=='2'"></button>
                    <button type="button" class="btn btn-sm btn-warning ml-2 " v-if="item.status=='3'"></button>
                </span>
            </div>
            <div class="col-12">
                <hr class="mt-5 mb-5">
                <p class="text-xs">Filter Tiket : </p>
                <select class='form-control' style="width:200px;" v-model="filterTicket">
                  <option value="semua">--- Semua ---</option>
                  <option value="adminapotek">Frontliner</option>
                  <option value="apoteker">Apoteker</option>
                  <option value="nurse">Nurse</option>
                  <option value="dokter">Dokter</option>
                  <option value="facial">Facial Therapist</option>
                </select>
                <hr class="mt-5 mb-5">
            </div>
            <div v-if="filterTicket.indexOf('adminapotek')!=-1 || filterTicket=='semua'" class="col-sm-6 shadow p-3 rounded-lg my-2" >
                  
                    <p class="font-semibold p-2">Frontliner</p>
                 <div class="" v-for="(item, index) in transaksiNota" :key="index+'nota'" v-show="transaksiNota.length>0">
                  <!-- LIST TRANSAKSI ADMIN -->
                    <div  v-if="item.proses=='1'||item.proses=='7'">
                    <div 
                    class="shadow hover:text-red-400 hover:shadow-lg anim cursor-pointer rounded-lg p-3 text-xs mb-2" >
                        <p class="float-right  font-semibold font-semibold text-xs ">{{format2(item.tanggal)}}</p>
                        <p class="font-semibold">{{item.nota}}  
                        </p>
                        <hr class="mt-1 mb-1">
                        <div class="float-right">
                        </div>
                        <table class="">
                        <tr>
                            <td class="font-semibold">Pasien</td>
                            <td class="pl-2 pr-2">:</td>
                            <td class="font-semibold">{{item.pelanggan}}</td>
                        </tr>
                            <tr>
                            <td class="font-semibold">Keterangan</td>
                            <td class="pl-2 pr-2">:</td>
                            <td class="font-semibold">{{item.catatan_admin}}</td>
                        </tr>
                        </table>
                        <button v-if="['dokter','SuperAdmin','adminpotek','Kasir'].includes($store.state.users.level)"
                         type="button" class="btn btn-sm btn-danger float-right mr-1 text-xs" @click="hapusTicket(item)"><span class="typcn typcn-delete"></span> ?</button>
                        <button  type="button" class="btn btn-sm btn-success float-right mr-2 text-xs" @click="showItem(item)"><span class="typcn typcn-eye"></span></button>
                        <button type="button" class="btn btn-sm float-left text-xs"
                        :class="`${item.proses=='1'?'btn-dark':item.proses=='2'||item.proses=='3'||item.proses=='4'?'btn-warning':item.proses=='5'?'btn-primary':item.proses=='6'?'btn-danger':item.proses=='7'?'btn-success':'btn-style10'}`">
                        <span class="typcn typcn-media-record"></span> {{item.proses=='1'?'Registered':item.proses=='2'?'Konsul':item.proses=='3'?'Treatment':item.proses=='4'?'Treatment Dokter':item.proses=='5'?'Buat Resep':item.proses=='6'?'Menyiapkan Obat':item.proses=='7'?'Bayar':'Selesai'}}
                        </button>
                    </div>
                </div>
                </div>
            </div>
            <div v-if="filterTicket.indexOf('apoteker')!=-1 || filterTicket=='semua'" class="col-sm-6 shadow p-3 rounded-lg my-2">
                  
                    <p class="font-semibold p-2">APOTEKER
                    </p>
                 <div class="" v-for="(item, index) in transaksiNota" :key="index+'nota'" v-show="transaksiNota.length>0">
                  <!-- LIST TRANSAKSI APOTEKER -->
                    <div  v-if="item.proses=='6'">
                    <div 
                    class="shadow hover:text-red-400 hover:shadow-lg anim cursor-pointer rounded-lg p-3 text-xs mb-2" >
                        <p class="float-right  font-semibold font-semibold text-xs ">{{format2(item.tanggal)}}</p>
                        <p class="font-semibold">{{item.nota}}  
                        </p>
                        <hr class="mt-1 mb-1">
                        <div class="float-right">
                        </div>
                        <table class="">
                        <tr>
                            <td class="font-semibold">Pasien</td>
                            <td class="pl-2 pr-2">:</td>
                            <td class="font-semibold">{{item.pelanggan}}</td>
                        </tr>
                            <tr>
                            <td class="font-semibold">Keterangan</td>
                            <td class="pl-2 pr-2">:</td>
                            <td class="font-semibold">{{item.catatan_admin}}</td>
                        </tr>
                        </table>
                        <button 
                        v-if="['dokter','SuperAdmin','adminpotek','Kasir'].includes($store.state.users.level)"
                        type="button" class="btn btn-sm btn-danger float-right mr-1 text-xs" @click="hapusTicket(item)"><span class="typcn typcn-delete"></span> ?</button>
                        <button  type="button" class="btn btn-sm btn-success float-right mr-2 text-xs" @click="showItem(item)"><span class="typcn typcn-eye"></span></button>
                        <button type="button" class="btn btn-sm float-left text-xs"
                        :class="`${item.proses=='1'?'btn-dark':item.proses=='2'||item.proses=='3'||item.proses=='4'?'btn-warning':item.proses=='5'?'btn-primary':item.proses=='6'?'btn-danger':item.proses=='7'?'btn-success':'btn-style10'}`">
                        <span class="typcn typcn-media-record"></span> {{item.proses=='1'?'Registered':item.proses=='2'?'Konsul':item.proses=='3'?'Treatment':item.proses=='4'?'Treatment Dokter':item.proses=='5'?'Buat Resep':item.proses=='6'?'Menyiapkan Obat':item.proses=='7'?'Bayar':'Selesai'}}
                        </button>
                    </div>
                </div>
                </div>
            </div>
            <div v-if="filterTicket.indexOf('nurse')!=-1 || filterTicket=='semua'" class="col-sm-6 shadow p-3 rounded-lg my-2">
                  
                    <p class="font-semibold p-2">NURSE
                    </p>
                 <div class="" v-for="(item, index) in transaksiNota" :key="index+'nota'" v-show="transaksiNota.length>0">
                  <!-- LIST TRANSAKSI NURSE -->
                    <div  v-if="item.proses=='3'">
                    <div 
                    class="shadow hover:text-red-400 hover:shadow-lg anim cursor-pointer rounded-lg p-3 text-xs mb-2" >
                        <p class="float-right  font-semibold font-semibold text-xs ">{{format2(item.tanggal)}}</p>
                        <p class="font-semibold">{{item.nota}}  
                        </p>
                        <hr class="mt-1 mb-1">
                        <div class="float-right">
                        </div>
                        <table class="">
                        <tr>
                            <td class="font-semibold">Pasien</td>
                            <td class="pl-2 pr-2">:</td>
                            <td class="font-semibold">{{item.pelanggan}}</td>
                        </tr>
                            <tr>
                            <td class="font-semibold">Keterangan</td>
                            <td class="pl-2 pr-2">:</td>
                            <td class="font-semibold">{{item.catatan_admin}}</td>
                        </tr>
                        </table>
                        <button 
                        v-if="['dokter','SuperAdmin','adminpotek','Kasir'].includes($store.state.users.level)"
                        type="button" class="btn btn-sm btn-danger float-right mr-1 text-xs" @click="hapusTicket(item)"><span class="typcn typcn-delete"></span> ?</button>
                        <button  type="button" class="btn btn-sm btn-success float-right mr-2 text-xs" @click="showItem(item)"><span class="typcn typcn-eye"></span></button>
                        <button type="button" class="btn btn-sm float-left text-xs"
                        :class="`${item.proses=='1'?'btn-dark':item.proses=='2'||item.proses=='3'||item.proses=='4'?'btn-warning':item.proses=='5'?'btn-primary':item.proses=='6'?'btn-danger':item.proses=='7'?'btn-success':'btn-style10'}`">
                        <span class="typcn typcn-media-record"></span> {{item.proses=='1'?'Registered':item.proses=='2'?'Konsul':item.proses=='3'?'Treatment':item.proses=='4'?'Treatment Dokter':item.proses=='5'?'Buat Resep':item.proses=='6'?'Menyiapkan Obat':item.proses=='7'?'Bayar':'Selesai'}}
                        </button>
                    </div>
                </div>
                </div>
            </div>
            <div v-if="filterTicket.indexOf('facial')!=-1 || filterTicket=='semua'" class="col-sm-6 shadow p-3 rounded-lg my-2">
                  
                    <p class="font-semibold p-2">Facial Therapist
                    </p>
                 <div class="" v-for="(item, index) in transaksiNota" :key="index+'nota'" v-show="transaksiNota.length>0">
                  <!-- LIST TRANSAKSI NURSE -->
                    <div  v-if="item.proses=='3A'">
                    <div 
                    class="shadow hover:text-red-400 hover:shadow-lg anim cursor-pointer rounded-lg p-3 text-xs mb-2" >
                        <p class="float-right  font-semibold font-semibold text-xs ">{{format2(item.tanggal)}}</p>
                        <p class="font-semibold">{{item.nota}}  
                        </p>
                        <hr class="mt-1 mb-1">
                        <div class="float-right">
                        </div>
                        <table class="">
                        <tr>
                            <td class="font-semibold">Pasien</td>
                            <td class="pl-2 pr-2">:</td>
                            <td class="font-semibold">{{item.pelanggan}}</td>
                        </tr>
                            <tr>
                            <td class="font-semibold">Keterangan</td>
                            <td class="pl-2 pr-2">:</td>
                            <td class="font-semibold">{{item.catatan_admin}}</td>
                        </tr>
                        </table>
                        <button 
                        v-if="['dokter','SuperAdmin','adminpotek','Kasir'].includes($store.state.users.level)"
                        type="button" class="btn btn-sm btn-danger float-right mr-1 text-xs" @click="hapusTicket(item)"><span class="typcn typcn-delete"></span> ?</button>
                        <button  type="button" class="btn btn-sm btn-success float-right mr-2 text-xs" @click="showItem(item)"><span class="typcn typcn-eye"></span></button>
                        <button type="button" class="btn btn-sm float-left text-xs"
                        :class="`${item.proses=='1'?'btn-dark':item.proses=='2'||item.proses=='3'||item.proses=='3A'||item.proses=='4'?'btn-warning':item.proses=='5'?'btn-primary':item.proses=='6'?'btn-danger':item.proses=='7'?'btn-success':'btn-style10'}`">
                        <span class="typcn typcn-media-record"></span> {{item.proses=='1'?'Registered':item.proses=='2'?'Konsul':item.proses=='3'||item.proses=='3A'?'Treatment':item.proses=='4'?'Treatment Dokter':item.proses=='5'?'Buat Resep':item.proses=='6'?'Menyiapkan Obat':item.proses=='7'?'Bayar':'Selesai'}}
                        </button>
                    </div>
                </div>
                </div>
            </div>
            <div v-if="filterTicket.indexOf('dokter')!=-1 || filterTicket=='semua'" class="col-sm-6 shadow p-3 rounded-lg my-2">
                  
                    <p class="font-semibold p-2">DOKTER
                    </p>
                 <div class="" v-for="(item, index) in transaksiNota" :key="index+'nota'" v-show="transaksiNota.length>0">
                  <!-- LIST TRANSAKSI DOKTER -->
                    <div  v-if="item.proses=='2'|| item.proses=='4'||item.proses=='5'">
                    <div 
                    class="shadow hover:text-red-400 hover:shadow-lg anim cursor-pointer rounded-lg p-3 text-xs mb-2" >
                        <p class="float-right  font-semibold font-semibold text-xs ">{{format2(item.tanggal)}}</p>
                        <p class="font-semibold">{{item.nota}}  
                        </p>
                        <hr class="mt-1 mb-1">
                        <div class="float-right">
                        </div>
                        <table class="">
                        <tr>
                            <td class="font-semibold">Pasien</td>
                            <td class="pl-2 pr-2">:</td>
                            <td class="font-semibold">{{item.pelanggan}}</td>
                        </tr>
                            <tr>
                            <td class="font-semibold">Keterangan</td>
                            <td class="pl-2 pr-2">:</td>
                            <td class="font-semibold">{{item.catatan_admin}}</td>
                        </tr>
                        </table>
                        <button 
                        v-if="['dokter','SuperAdmin','adminpotek','Kasir'].includes($store.state.users.level)"
                        type="button" class="btn btn-sm btn-danger float-right mr-1 text-xs" @click="hapusTicket(item)"><span class="typcn typcn-delete"></span> ?</button>
                        <button  type="button" class="btn btn-sm btn-success float-right mr-2 text-xs" @click="showItem(item)"><span class="typcn typcn-eye"></span></button>
                        <button type="button" class="btn btn-sm float-left text-xs"
                        :class="`${item.proses=='1'?'btn-dark':item.proses=='2'||item.proses=='3'||item.proses=='4'?'btn-warning':item.proses=='5'?'btn-primary':item.proses=='6'?'btn-danger':item.proses=='7'?'btn-success':'btn-style10'}`">
                        <span class="typcn typcn-media-record"></span> {{item.proses=='1'?'Registered':item.proses=='2'?'Konsul':item.proses=='3'?'Treatment':item.proses=='4'?'Treatment Dokter':item.proses=='5'?'Buat Resep':item.proses=='6'?'Menyiapkan Obat':item.proses=='7'?'Bayar':'Selesai'}}
                        </button>
                    </div>
                </div>
                </div>
            </div>
          </div>
      </div>
    <div style="height:200px;"></div>
  </div>
</template>

<script>
// TOTURIAL 
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/_autophp.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
let sdb = new autophp();
let sdb2 = new autophp();
import firebase from 'firebase'
import uniqid from 'uniqid'
let rdb=firebase.database()
import _ from 'lodash'
export default {
layout:'app_shop_admin',
  components: {
    Btables,
  },
  data() {
    return {
      pilih:'adminapotek',
      ticketType:'latest',
      nota:null,
      listuser:[],
      selected: false,
      modalbayar:false,
      modalstatus:false,
      modalItem:false,
      listItem:[],
      filterTicket:'semua',
      proses:'',
      vdata: {
          jumlah:0,
          catatan_admin:''
      },
      produks:[],
      transaksiNota:[],
      pelanggans:[],
      ticket:null,
      btn: "tambah",
      stok:0,
      datanya:[],
      total:0,
      uang:'',
      diskons:false,
      skip_ambil_diskon:false,
      satuans:[],
      total_beli_pelanggan:0,
      membership:0,
      diskon_membership:0,
    };
  },
  watch:{
    pilih(){
      this.getProduknya()
    }
  },
  methods: {
      modalstatusshow(item){
          if(item.username==this.$store.state.users.username){
              this.modalstatus=true;
              this.statuspilih=item.status
          }
      },
      ubahstatus(item){
          let that=this;
          let data={
              id:this.$store.state.users.id,
              status:item
          }
          sdb.collection('tbuser',false).doc().set(data).then(res=>{
            sdb.alert('Status Berubah!','bg-green-400')
            that.getUser();
            that.modalstatus=false;
          })
      },
      getUser(){
        sdb.collection("tbuser",false).doc().select(`select username,status from tbuser where level='adminapotek' || level='apoteker' || level='dokter' || level='nurse' || level='facial'`).then(res => {
            this.listuser=res;
            this.$forceUpdate();
        });
      },
    format(tanggal) {
        return this.$datefns.format(new Date(tanggal),"YYYY-MM-DD");
      },
      format2(tanggal) {
        return this.$datefns.format(new Date(tanggal),"DD-MM-YYYY HH:mm");
      },
      format3(tanggal) {
        return this.$datefns.format(new Date(tanggal),"DD-MM-YYYY");
      },
      getJam(tanggal) {
        return this.$datefns.format(new Date(),"HH");
      },
      async hapusTicket(item){
        console.log(item)
        if(confirm(`Apakah hapus ticket transaksi ini ${item.nota} ?`)){
          await sdb.collection("app_kasir_keranjang",false).doc().select(`DELETE FROM app_kasir_keranjang where nota='${item.nota}'`)
          await sdb.collection("app_kasir_keranjang",false).doc().select(`DELETE FROM app_kasir_transaksi_nota where nota='${item.nota}'`)
          this.getData();
        }
      },
      async showItem(item){
        this.modalItem=true;
        this.listItem = await sdb.collection("app_kasir_keranjang",false).doc().select(`select tb1.*,tb2.nama_produk,tb2.harga from app_kasir_keranjang tb1 left join app_kasir_produk tb2 on tb2.id=tb1.id_produk where tb1.nota='${item.nota}'`)
        this.$forceUpdate();
      },
      getNota(){
          let code='KA';//KA = kasir, ON=Online, OF=OFFLINE
          sdb.collection("app_kasir_transaksi_nota").doc().get("*",`where id_user='${this.$store.state.users.id}' order by id`).then(res => {
              this.vdata.nota=code+this.format(new Date()).split('-').join('')+'-'+this.$store.state.users.id+'-'+res.length;
              console.log('nota',this.vdata.nota)
              this.$forceUpdate();
          });
      },
     async cekstok(item){
        console.log(this.vdata)
          let stok=await sdb.collection("app_kasir_produk").doc().get("*",`where id='${this.vdata.id_produk}' order by id`).then(res => {
         if(!this.skip_ambil_diskon){
             this.vdata.diskon=res[0].diskon
         }
          this.vdata.harga=res[0].harga
          this.vdata.satuan=res[0].satuan
        return parseInt(res[0].stok)
      });
      let masuk = await sdb.collection("app_kasir_stok_masuk").doc().get("*",`where id_produk='${this.vdata.id_produk}' order by id`).then(res => {
        return res.reduce((e,n)=>{
          return e+parseInt(n.jumlah)
        },0)
      });
      let keluar = await sdb.collection("app_kasir_stok_keluar").doc().get("*",`where id_produk='${this.vdata.id_produk}' order by id`).then(res => {
        return res.reduce((e,n)=>{
          return e+parseInt(n.jumlah)
        },0)
      });
      
      // let transaksi=await sdb.collection("app_kasir_transaksi_nota",false).doc().select(`SELECT * FROM app_kasir_transaksi_nota tb1 left join app_kasir_keranjang tb2 on tb1.nota=tb2.nota where tb2.id_produk='${this.vdata.id_produk}' order by tb1.id`).then(res => {
      //      return res.reduce((e,n)=>{
      //       return e+parseInt(n.jumlah)
      //       },0)
      // });
       let keranjang=await sdb.collection("app_kasir_keranjang",false).doc().select(`SELECT * FROM app_kasir_keranjang  tb1 where tb1.id_produk='${this.vdata.id_produk}' order by tb1.id`).then(res => {
           return res.reduce((e,n)=>{
            return e+parseInt(n.jumlah)
            },0)
      });
      stok=stok+(masuk-keluar-keranjang)
      this.stok=stok;
      this.hitung_total();
      this.$forceUpdate();
    },
    hitung_diskon(){
        let data= this.datanya;
        if(data.length>0){
            this.total=data.reduce((e,n)=>{
                let diskon = (parseInt(n.diskon)/100)*parseInt(n.harga)
                return e+((parseInt(n.harga)*parseInt(n.jumlah))-diskon)
            },0)
            this.diskons=true;
        }
        this.$forceUpdate();
    },
    hitung_total(){
      console.log('satuans',this.satuans)
        let satuan=parseInt(this.satuans.filter(e=>e.id==this.vdata.satuan)[0].perkalian)
        this.vdata.total=(parseInt(this.vdata.harga)*satuan)*this.vdata.jumlah-((parseInt(this.vdata.diskon)/100)*parseInt(this.vdata.harga)*this.vdata.jumlah)
        this.$forceUpdate();
    },
    deleteAll2(){
            let ids = [];
            ids=this.datanya.map(e=>e.id_user);
            sdb.collection("app_kasir_keranjang",false).doc().select(`DELETE FROM app_kasir_keranjang WHERE id_user IN (${ids.toString()}) AND posisi='keranjang'`).then(res => {
                console.log(res);
                this.getData();
            });
    },
    getDatanya(){
      sdb.collection("app_kasir_keranjang",false).doc().select(`
      select tb1.id,tb1.id_user,tb1.nota,tb1.tanggal,tb1.id_pelanggan,tb2.barcode,tb1.harga,tb1.jumlah,tb2.nama_produk,tb1.id_produk,tb1.diskon,tb1.total,tb1.tanggal_w
      from app_kasir_keranjang tb1 left join app_kasir_produk tb2 on tb2.id=tb1.id_produk where tb1.id_user='${this.$store.state.users.id}' AND tb1.nota='${this.vdata.nota}'`).then(res => {
        this.datanya=res;
        if(res.length>0){
            this.total=res.reduce((e,n)=>{
                return e+(parseInt(n.total))
            },0)
            let date = this.format(new Date()).split('-')[2]
            if(res[0].tanggal_w.split('-')[2]==date){
            }else{
                setTimeout(() => {
                    this.deleteAll2();
                }, 1000);
            }
            this.hitung_total();
        }
        this.$forceUpdate();
      });
    },
    getProduknya(){
      var type='1';
      if(this.pilih=='dokter' || this.pilih=='nurse'){
        type='3'
      }
      sdb.collection("app_kasir_produk").doc().get("*",`where type='${type}' order by id`).then(res => {
          this.produks=res;
          this.produks.map(function (x){ // taruh setelah produks di initial
                return x.item_data = x.barcode + ' / ' + x.nama_produk;
          });
      });
    },
    getData(){
      this.getDatanya();
      this.getProduknya();
       sdb.collection("app_kasir_pelanggan").doc().get("*","order by id").then(res => {
          this.pelanggans=res;
          this.pelanggans.map(function (x){ // taruh setelah produks di initial
                  return x.item_data = x.nama + ' / ' + x.nohp;
          });
          this.$forceUpdate();
      });
      sdb.collection("app_kasir_transaksi_nota",false).doc().select(`
      select tb1.nota,tb1.id,tb1.tanggal,tb1.proses,tb2.id as id_pelanggan,tb2.nama as pelanggan,tb3.username as penginput,tb1.catatan_admin,tb1.catatan_dokter,tb1.catatan_apoteker,tb1.catatan_nurse from app_kasir_transaksi_nota tb1 left join app_kasir_pelanggan tb2 on tb2.id=tb1.id_pelanggan left join tbuser tb3 on tb3.id=tb1.id_user
      where tb1.status!='delete' order by id asc
      `).then(res => {
        this.transaksiNota=res;
        console.log('transaksinota',res)
        this.$forceUpdate();
      });
    
      
      
    },
    getTanggal(){
        this.vdata.tanggal=this.$datefns.format(new Date(),"YYYY-MM-DDTHH:mm")
    },
    getSatuan(){
      sdb.collection("app_kasir_produk_satuan").doc().get("*","order by id").then(res => {
          this.satuans=res;
          this.$forceUpdate();
      });
      
    }
  },
  async mounted() {
      this.getUser();
     this.vdata= {
          jumlah:0,
          catatan_admin:''
     }
    this.pilih=this.$store.state.users.level
    let that=this;
     rdb.ref(`/scan/${that.$store.state.users.id}/barcode`).on('value',(e)=>{ //read
          if(that.produks.length>0){
            console.log('produks',that.produks)
            let data =that.produks.filter(k=>{
              console.log(k.barcode)
              if(k.barcode==e.val().id){
                return k
              }
            })
            console.log(data)
            that.vdata.id_produk=data[0].id
            that.cekstok();
            that.$forceUpdate();
          }
    }) 
    rdb.ref(`/ticket/admin`).on('value',(e)=>{ //read
        that.getData();
    }) 
      setTimeout(() => {
        // this.getNota();
        this.getData();
        this.getSatuan();
    }, 1000);
    this.getTanggal();
  },
};
</script>
<style>
.te{
    color:rgba(7, 6, 6, 0.671);
}
</style>